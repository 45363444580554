$color-red: #f44336;
$color-teal: #0ea7b5;
$color-blue-dark: #0c457d;
$color-blue-light: #337ab7;
$color-orange: #e8702a;
$color-orange-light: #f9dbca;
$color-grey-light: #f1f1f1;
$color-grey-light-2: #efefef;
$color-grey-light-3: #eeeeee;
$color-green: #41b441;
