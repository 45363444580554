@import 'variables';

.button {
  display: inline-block;

  padding: 18px 20px !important;

  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border: none;
  border-radius: 100px !important;

  white-space: nowrap;
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1 !important;

  transform: translate3d(0, 0, 0);
  transition: background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
  }

  &:not(:disabled):active {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 8px 10px 1px rgba(241, 230, 230, 0.14),
      0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }

  &--primary {
    background-color: $color-blue-dark;

    color: #ffffff;
  }

  &--secondary {
    background-color: $color-orange;

    color: #ffffff;
  }
}

.button_new-item {
  margin-top: 25px;
  padding: 12px 24px !important;

  background-color: #ccc;
  box-shadow: none;

  color: #333;
}
